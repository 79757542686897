import React, { useState, useEffect, useMemo } from 'react';
import { Typography, Box } from '@mui/material';
import CodeMirror from '@uiw/react-codemirror';
import { StreamLanguage } from '@codemirror/language';
import { markdown } from '@codemirror/lang-markdown';
import { javascript } from '@codemirror/lang-javascript';
import { json } from '@codemirror/lang-json';
import { rust } from '@codemirror/lang-rust';
import { cpp } from '@codemirror/lang-cpp';
import { java } from '@codemirror/lang-java';
import { python } from '@codemirror/lang-python';
import { go } from '@codemirror/lang-go';
import { xml } from '@codemirror/lang-xml';
import { yaml } from '@codemirror/lang-yaml';
import { sql } from '@codemirror/lang-sql';
import { shell } from '@codemirror/legacy-modes/mode/shell';
import { mathematica } from '@codemirror/legacy-modes/mode/mathematica';
import { PumpingTask, TaskFile } from '../types';
import { EditorView } from '@codemirror/view';
import { material } from '@uiw/codemirror-theme-material';

interface CodeEditorProps {
    selectedFile: TaskFile;
    setSelectedFile: React.Dispatch<React.SetStateAction<TaskFile | undefined>>;
    currentTask: PumpingTask;
    setCurrentTask: (task: PumpingTask) => void;
    setCanRunCode: (canRunCode: boolean) => void;
}

const fileExtensions: { [key: string]: string } = {
    md: 'markdown',
    js: 'javascript',
    ts: 'typescript',
    json: 'json',
    rs: 'rust',
    c: 'cpp',
    cpp: 'cpp',
    java: 'java',
    py: 'python',
    go: 'go',
    xml: 'xml',
    sh: 'bash',
    yml: 'yaml',
    yaml: 'yaml',
    sql: 'sql',
    csv: 'mathematica',
};

const CodeEditor: React.FC<CodeEditorProps> = ({
    selectedFile,
    setSelectedFile,
    currentTask,
    setCurrentTask,
    setCanRunCode,
}) => {
    const [code, setCode] = useState<string>('');

    useEffect(() => {
        const fileContent = selectedFile.content || '';
        setCode(fileContent);
        checkCanRunCode(fileContent);
    }, [selectedFile]);

    const checkCanRunCode = (code: string) => {
        if (!code.trim() && !currentTask?.framework) {
            setCanRunCode(false);
        } else {
            setCanRunCode(true);
        }
    };

    const handleEditorChange = (code: string) => {
        if (!currentTask || !currentTask.files) {
            return;
        }
        const updatedFiles = currentTask.files.map((file) => {
            if (file.path === selectedFile.path) {
                return { ...file, content: code };
            }
            return file;
        });
        setCurrentTask({ ...currentTask, files: updatedFiles });
        selectedFile.content = code;
        setCode(code);
        setSelectedFile(selectedFile);
        checkCanRunCode(code);
    };

    const extensions = useMemo(() => {
        if (!currentTask || !currentTask.language) return [];
        const selectedFileExtension: string | undefined = selectedFile
            .path.split('.')
            .pop()
            ?.toLowerCase();
        const language: string = selectedFileExtension
            ? fileExtensions[selectedFileExtension] || currentTask.language
            : currentTask.language;

        const customTheme = EditorView.theme({
            '&': {
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                fontSize: '0.8rem',
            },
            '.cm-editor': {
                flex: '1 1 auto',
                display: 'flex',
                flexDirection: 'column',
            },
            '.cm-scroller': {
                overflow: 'auto',
                flex: '1 1 auto',
                paddingRight: '10px',
                paddingBottom: '10px',
            },
        });

        switch (language) {
            case 'markdown':
                return [customTheme, markdown()];
            case 'javascript':
                return [customTheme, javascript()];
            case 'typescript':
                return [customTheme, javascript({ typescript: true })];
            case 'json':
                return [customTheme, json()];
            case 'rust':
                return [customTheme, rust()];
            case 'cpp':
                return [customTheme, cpp()];
            case 'java':
                return [customTheme, java()];
            case 'python':
                return [customTheme, python()];
            case 'go':
                return [customTheme, go()];
            case 'xml':
                return [customTheme, xml()];
            case 'yaml':
                return [customTheme, yaml()];
            case 'sql':
                return [customTheme, sql()];
            case 'mathematica':
                return [customTheme, StreamLanguage.define(mathematica)];
            case 'bash':
                return [customTheme, StreamLanguage.define(shell)];
            default:
                return [customTheme];
        }
    }, [currentTask, selectedFile]);

    if (selectedFile.is_binary) {
        return (
            <div style={{ padding: '16px', height: '100%', marginLeft: 20 }}>
                <Typography variant='body1'>Бинарный файл</Typography>
            </div>
        );
    }
    return (
        <Box
            style={{
                paddingLeft: 0,
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflow: 'hidden', // Added to prevent parent scroll
            }}
        >
            <CodeMirror
                value={code}
                extensions={extensions}
                theme={material}
                onChange={handleEditorChange}
                height='100%'
                basicSetup={{
                    lineNumbers: true,
                    foldGutter: true,
                    highlightActiveLineGutter: true,
                    history: currentTask.files.length === 1,
                }}
                editable={!(selectedFile.readonly || currentTask.read_only)}
                style={{ flexGrow: 1 }}
            />
        </Box>
    );
};

export default CodeEditor;
